.close{
  padding-bottom: 30px;
  text-align: center;

  .job-note-row1{
    color: #ff0000;
    font-size: 22px;
    font-weight: bolder;
  }

  .job-note-row2{
    color: #0000cc;
    font-size: 14px;
    font-weight: bold;
  }

  .job-note-row3{
    color: #0000cc;
    font-size: 14px;
    font-weight: bold;
  }
}