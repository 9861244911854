@import "../../../../../Assets/scss/primaryColor";
.carousel {
  .carousel-item {
    height: 80vh;
    img {
      height: inherit;
      max-height: 100vh;
      object-fit: cover;
    }
    .carousel-caption {
      top: 50%;
      transform: translateY(-50%);
      bottom: initial;
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      transform-style: preserve-3d;

      @media (min-width: 500px) {
        h5 {
          font-size: 4rem;
          font-weight: 900;
          color: $white;
        }
      }
      @media (max-width: 500px) {
        h5 {
          font-size: 1.4rem;
          font-weight: 900;
          color: $white;
        }
      }
    }
  }
  .carousel-item:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
  }
}
