@import "../../../../../Assets/scss/primaryColor";
.register-header {
  width: 100%;

  .logo {
    width: 80px;
    height: 80px;
  }
  .navbar {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    .dropdown {
      .dropdown-menu::before {
        border-bottom: 9px solid rgba(0, 0, 0, 0.2);
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: 5%; /* position */
        position: absolute;
        top: -8px;
      }

      .dropdown-menu::after {
        border-bottom: 8px solid #ffffff;
        border-left: 9px solid rgba(0, 0, 0, 0);
        border-right: 9px solid rgba(0, 0, 0, 0);
        content: "";
        display: inline-block;
        left: 5%; /* position */
        position: absolute;
        top: -7px;
      }
    }
  }
  .topnavs {
    position: relative;
    top: 0;
    background: #28338f;
    // background: #002a38;

    // height: 30px;
    font-size: 0.8rem;
    padding: 6px 20px;
    color: $white;
  }

  .ost-multi-header {
    position: relative;
  }
  .ost-multi-header::after {
    content: "";
    height: 5px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;

    //   background:   rgb(1, 0, 103) rgb(251, 254, 0) rgb(28, 128, 22); /* Old browsers */

    //   background: -moz-linear-gradient(left, rgb(251, 254, 0) 0%, rgb(251, 254, 0) 43%, rgb(1, 0, 103) 43%, rgb(1, 0, 103) 76%, rgb(28, 128, 22) 76%, rgb(28, 128, 22) 100%); /* FF3.6-15 */

    //   // background: -webkit-linear-gradient(left, rgb(251, 254, 0) 0%,rgb(251, 254, 0) 43%,rgb(1, 0, 103) 43%,rgb(1, 0, 103) 76%,rgb(28, 128, 22) 76%,rgb(28, 128, 22) 100%); /* Chrome10-25,Safari5.1-6 */

    //   background: linear-gradient(to right, rgb(251, 254, 0) 0%,rgb(251, 254, 0) 43%,rgb(1, 0, 103) 43%,rgb(1, 0, 103) 76%,rgb(28, 128, 22) 76%,rgb(28, 128, 22) 100%);

    //   /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    //   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ed8034', endColorstr='#2184cd',GradientType=1 ); /* IE6-9 */
  }

  .pcolorFooter {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr;
    height: 5px;
    width: 100%;
    position: absolute;
    bottom: 0;

    .section-color-tage1 {
      background-color: rgb(1, 0, 103);
    }

    .section-color-tage2 {
      background-color: rgb(251, 254, 0);
    }

    .section-color-tage3 {
      background-color: rgb(28, 128, 22);
    }
  }

  @media (min-width: 992px) {
    .navbar-expand-lg .navbar-collapse {
      flex-basis: 900px;
    }
  }
}
