@import "./Assets/scss/primaryColor";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $white;
  overflow-x: hidden !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.emp-card {
  border-top: 5px solid rgb(50, 138, 0) !important;
}

.btn-warning {
  background-color: rgb(251, 254, 0) !important;
  border: 1px solid rgb(251, 255, 0) !important;
  border-radius: 0px !important;
  font-weight: 700 !important;
}
