.main-app {
  position: relative;
  height: 100%;
  main {
    margin-bottom: 100px;
  }
  .remitaIcon {
    width: 400px;
    @media (max-width: 400px) {
      width: 100% !important;
    }
  }

}
.pcolorFooter {
  display: grid;
  grid-template-columns: 1.5fr 1fr 1.5fr;
  height: 3px;
  width: 100%;
  position: absolute;
  bottom: 0;


  .section-color-tage1 {
    background-color: rgb(1, 0, 103)
  }

  .section-color-tage2 {
    background-color: rgb(251, 254, 0)
  }

  .section-color-tage3 {
    background-color: rgb(28, 128, 22)
  }

}