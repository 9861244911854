.slip-background{
    background-image: linear-gradient(rgba(255, 255, 255, 0.946), rgba(255, 255, 255, 0.972)), url('../../../../../Assets/images/PoliceCadets.jpg');
    // opacity: 0.1;
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
}

.text_personal{
    font-size: 13px;
    // font-weight: bold;
    // color: #000000;
}
.title_personal{
    font-size: 24px;
    font-weight: bold;
    // color: #000000;
}