.exam-slip {
  min-height: 60vh;
  display: flex;
  flex-direction: column;
}
.slip-confirmation {
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
      to bottom,
      rgba(252, 252, 252, 0.897),
      rgba(248, 248, 248, 0.959)
    ),
    url(https://hometown.ng/wp-content/uploads/2016/11/tumblr_inline_nttutyJLHp1sjy75e_540.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .conf-head {
    h1 {
      font-size: 1.3rem;
      font-weight: 600;
    }
  }
}

.examslip {
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
}
