.footer-app {
  width: 100%;
  bottom: 0;
  position: relative;

  .pcolorFooter {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr;
    height: 3px;
    width: 100%;
    top: 0;


    .section-color-tage1 {
      background-color: rgb(1, 0, 103)
    }

    .section-color-tage2 {
      background-color: rgb(251, 254, 0)
    }

    .section-color-tage3 {
      background-color: rgb(28, 128, 22)
    }

  }
}
