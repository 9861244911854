/* 
Template name: Recruitment Form 
--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------
## Layouts
# Components
	- Header
	- Main
	- Section
    - Footer 
    - Media queries
--------------------------------------------------------------*/

:root {
  /* police color variatin  */
  --main-color-red: rgb(251, 1, 2);
  /* color blue with alpha */
  --main-color-blue: rgb(1, 0, 102);
  --main-color-light-blue: rgb(34, 72, 187);
  /* --main-color-blue-gradient-image: linear-gradient(180deg, rgb(1, 51, 202) 0%, rgb(13, 37, 110) 100%), url('./images/police_banner.jpg') no-repeat; */
  /* color blue with alpha */
  --main-color-blue-a: rgba(1, 0, 102, 0.9);
  /* color blue with alpha for forms section header bg gradient*/
  /* --main-color-section-blue-a: rgba(52, 52, 100, 0.9); */
  --main-color-section-blue-a: rgb(41, 51, 143);
  /* color darkblue */
  --main-color-darkblue: rgb(52, 52, 100);
  --main-color-darkblue-gradient: linear-gradient(
    rgba(52, 52, 100, 0.95),
    rgba(36, 32, 95, 0.95)
  );
  --main-color-darkblue-gradient-webkit: -webkit-linear-gradient(
    rgba(41, 51, 143, 0.95),
    /*  rgba(52, 52, 100, 0.95),
    rgba(36, 32, 95 0.95) */
      rgba(41, 51, 143, 0.95) ;
  );
  /* color darkblue with alpha for forms section header bg gradient*/
  --main-color-darkblue-a: rgb(18, 16, 56, 0.9);
  --main-color-section-darkblue-a: rgb(18, 16, 56, 0.9);
  /* --main-color-darkblue-gradient-image: linear-gradient(180deg, rgba(52, 52, 100, 0.911) 0%, rgba(36, 32, 95, 0.89) 100%), url('./images/police_banner.jpg') no-repeat; */
  --main-color-green: rgb(27, 128, 22);
  --main-color-dark: rgba(2, 0, 36, 1);
  /* --main-color-yellow: rgb(251, 254, 0); */
  --main-color-yellow: rgba(8, 7, 26, 0.9);
  --main-color-grey: rgb(52, 58, 64);
  --main-color-grey-light: hsl(0, 0%, 86%);
  --main-color-grey-gradient: linear-gradient(
    180deg,
    rgba(178, 178, 178, 0.94) 0%,
    rgba(121, 120, 120, 0.94) 100%
  );
  /* font color white    */
  --main-text-light: rgb(248, 249, 250);
  /* font color dark  */
  --main-text-dark: rgb(52, 58, 64);
  /* input label color    */
  --main-section-label-color: rgb(52, 58, 64);
  /* input label font size   */
  --section-label-font-size: 1rem;
  --section-paragraph-size: rgb(52, 58, 64);
  --section-label-size: rgb(52, 58, 64);
  --main-h1: 2rem;
  --main-h2: 1.1rem;
  --main-h3: 1rem;
  --main-h4: 0.9rem;
  --main-h5: 0.8rem;
  --main-h6: 0.7rem;
  --main-text-shadow: 0px 0px 0px #000000;
  --main-input-icon-color: rgb(149, 152, 155);
}

body {
  /* background: var(--main-color-blue); */
  background: #f3f3f3;
  /* background: linear-gradient(180deg, rgba(252, 252, 252, 0.4) 0%, rgba(229, 229, 236, 0.9) 100%), url('./images/body_bg.jpeg') no-repeat; */
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* Header  
--------------------------------------------------------------*/

/* header logo size */

.header-logo-size {
  width: 70px;
}

/* header background color with image gradient */

.header-bg {
  background: var(--main-color-blue);
  background: var(--main-color-darkblue-gradient-image);
  /* background-color: #fff !important; */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

/* align the header items */

.head-container {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.header-title {
  font-size: 35px;
  font-weight: 600;
  color: var(--main-text-light);
}

.header-sub-title {
  font-size: 28px;
  color: var(--main-text-light);
}

/* Section start 

--------------------------------------------------------------*/

.section-header-bg {
  background: -webkit-linear-gradient(
    var(--main-color-section-blue-a),
    var(--main-color-section-darkblue-a)
  ) !important;
  background: linear-gradient(
    var(--main-color-section-blue-a),
    var(--main-color-section-darkblue-a)
  ) !important;
  color: var(--main-text-light);
}

.emp-logcard-head {
  background: -webkit-linear-gradient(
    var(--main-color-section-blue-a),
    var(--main-color-section-darkblue-a)
  ) !important;
  background: linear-gradient(
    var(--main-color-section-blue-a),
    var(--main-color-section-darkblue-a)
  ) !important;
}

.section-container {
  display: grid;
  /* grid-template-columns: 0.6% 98.9% 0.6%; */
}

.section-container-notice {
  display: grid;
  grid-template-columns: 0.6% 98.9% 0.6%;
}

.section-item-container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  /* width: 5px;  */
  /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: 5px;
    /* width: 1%; */
  /* position: absolute;  
    bottom: 0; */
}

.section-color-tage1 {
  background-color: var(--main-color-green);
}

.section-color-tage2 {
  background-color: var(--main-color-light-blue);
}

.section-color-tage3 {
  background-color: var(--main-color-yellow);
}

.section-color-tage4 {
  background-color: var(--main-color-green);
}

.section-color-tage5 {
  background-color: var(--main-color-light-blue);
}

.header {
  position: relative;
}

section {
  margin-top: 20px;
  margin-bottom: 20px;
  /* box-shadow: 1px 10px 20px rgba(111, 113, 117, 0.5);
  -webkit-box-shadow: 1px 10px 20px rgba(111, 113, 117, 0.5);
  -moz-box-shadow: 1px 10px 10px rgba(111, 113, 117, 0.5); */
}

/* ref id section style */

.setion-intro {
  font-size: 25px;
  text-shadow: var(--main-text-shadow);
}

.attention {
  color: red;
  font-weight: bold;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-header > h1 {
  font-size: 25px;
  text-shadow: var(--main-text-shadow);
}

.card-header-notice > h1 {
  font-size: 25px;
  text-shadow: var(--main-text-shadow);
}

.fixedElement {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 100;
}

.notice-title {
  margin: -5px;
  padding: 10px;
}

.im-info {
  border-radius: 50px !important;
  width: 20px !important;
}

.section-persional-container-pro {
  display: flex;
  justify-content: center;
}

.section-persional-pro {
  background: var(--main-color-grey-light);
  height: 120px;
  width: 120px;
  border: 1px dashed var(--main-color-blue);
}

.noticer {
  font-size: 13px;
  text-align: center;
  padding: 40px;
}

.card-header-notice {
  margin-right: -30px !important;
}

/*Responsive  Media queries

--------------------------------------------------------------*/

/* Extra small devices (phones, 600px and down) */

@media only screen and (max-width: 600px) {
  .healign {
    text-align: center !important;
  }
  .head-logo {
    text-align: center !important;
  }
  .section-header-bg > h1 {
    font-size: 16px;
  }
  .header-title {
    font-size: 25px;
    font-weight: 600;
    color: var(--main-text-light);
    text-align: center !important;
    padding-top: 10px;
  }
  .header-sub-title {
    font-size: 16px;
    color: var(--main-text-light);
    text-align: center !important;
  }
  .notice-board {
    min-width: 100%;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */

@media only screen and (min-width: 600px) {
  .healign1 {
    text-align: center !important;
  }
  .healign2 {
    text-align: center !important;
  }
  .header-title {
    color: var(--main-text-light) !important;
  }
  .header-sub-title {
    color: var(--main-text-light) !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */

@media only screen and (min-width: 768px) {
  .healign1 {
    text-align: center !important;
  }
  .healign2 {
    text-align: center !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */

@media only screen and (min-width: 992px) {
  .healign1 {
    text-align: center !important;
  }
  .healign2 {
    text-align: center !important;
  }
  .navbar-brand {
    width: 100%;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */

@media only screen and (min-width: 1200px) {
  .healign1 {
    text-align: right !important;
  }
  .healign2 {
    text-align: left !important;
  }
  .navbar-brand {
    width: 100%;
  }
}

/* FIle upload fraction 
--------------------------------------------------------------*/

.file-upload {
  background-color: var(--main-text-light);
  width: 600px;
  margin: 0 auto;
  padding: 20px;
}

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: var(--main-text-light);
  background: #1fb264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid #15824b;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: var(--main-color-blue-a);
  color: var(--main-text-light);
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed var(--main-color-blue);
  position: relative;
}

.image-dropping,
.image-upload-wrap:hover {
  background-color: var(--main-color-blue);
  border: 4px dashed var(--main-color-blue);
  color: var(--main-text-light);
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: var(--main-text-light);
}

.image-title-wrap:hover {
  padding: 0 15px 15px 15px;
  color: var(--main-text-light);
}

.drag-text {
  text-align: center;
}

.drag-text h3 {
  font-weight: 100;
  text-transform: uppercase;
  color: var(--main-color-darkblue-a);
  padding: 60px 0;
}

.h33:hover {
  color: rgba(219, 224, 230, 0.95) !important;
}

.drag-text h3.remove-image:hover {
  color: rgba(203, 207, 212, 0.95);
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: var(--main-text-light);
  background: var(--main-color-red);
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid var(--main-color-red);
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: var(--main-text-light);
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}

label {
  font-weight: bolder;
}

.drag-text > h3:hover {
  color: var(--main-text-light);
}

/* Footer section
--------------------------------------------------------------*/

.footer-bg {
  background: var(--main-color-green);
  background: var(--main-color-green);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.bg-pp2 {
  background: var(--main-color-grey);
  background: var(--main-color-grey-gradient);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.submit-btn-bg {
  background: var(--main-color-darkblue-gradient-webkit);
  background: var(--main-color-darkblue-gradient);
  color: var(--main-text-light);
}

.foot-last {
  background-color: var(--main-color-yellow);
  color: #272626;
}

.col-boder {
  border-right: 1px solid var(--main-color-yellow);
  /* margin-right:10px !important;
     padding-right:10px !important; */
  margin-bottom: 30px;
}

.col-boder i,
.col-boder h6 {
  margin-bottom: 10px;
}

.footer-bg-main {
  background: linear-gradient(
    var(--main-color-section-blue-a),
    var(--main-color-section-darkblue-a)
  ) !important;
  /* background-color: var(--main-color-blue); */
}

@media (max-width: 767px) {
  .col-boder {
    border-right: 0px;
  }
}

.footer-bg {
  background-color: var(--main-color-green) !important;
}

/* Typography
--------------------------------------------------------------*/

html {
  scroll-behavior: smooth;
}

h1 {
  font-size: var(--main-h1);
  text-shadow: var(--main-text-shadow);
}

h2 {
  font-size: var(--main-h2);
  text-shadow: var(--main-text-shadow);
}

h3 {
  font-size: var(--main-h3);
}

h4 {
  font-size: var(--main-h3);
  font-weight: bold;
}

h5 {
  font-size: var(--main-h3);
}

h6 {
  font-size: var(--main-h3);
}

.nav-link {
  font-weight: 15px;
  font-weight: bold;
  color: var(--main-text-dark) !important;
}

.button {
  background: var(--main-color-darkblue-gradient);
}

.btn-success {
  background: var(--main-color-green) !important;
  border: none;
}

.btn-larg {
  border-radius: 100px !important;
  font-size: 25px;
  font-weight: 700;
  color: var(--main-text-light);
  height: 70px !important;
}

.btn-larg:hover {
  color: #7a80d8 !important;
}

.card-header > h1 {
  color: var(--main-text-light);
}

.refid-header h4 h3 {
  color: var(--main-text-light);
}

.input-group-text > i {
}

.btn-default {
  background-color: var(--main-color-grey-light);
}

.card-bg {
  background: var(--main-color-darkblue-gradient);
}

.bottom-support {
  font-size: 12px;
}

.pcolorFooter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  height: 5px;
  width: 100%;
  position: absolute;
  bottom: 0;
}

#myBtn {
  display: none;
  /* Hidden by default */
  position: fixed;
  /* Fixed/sticky position */
  bottom: 20px;
  /* Place the button at the bottom of the page */
  right: 30px;
  /* Place the button 30px from the right */
  z-index: 99;
  /* Make sure it does not overlap */
  border: none;
  /* Remove borders */
  outline: none;
  /* Remove outline */
  color: var(--main-text-light);
  /* Text color */
  cursor: pointer;
  /* Add a mouse pointer on hover */
  padding: 15px;
  /* Some padding */
  border-radius: 10px;
  /* Rounded corners */
  font-size: 18px;
  /* Increase font size */
  background-color: var(--main-color-darkblue-gradient);
}

#myBtn:hover {
  background-color: var(--main-color-grey-gradient);
  /* Add a dark-grey background on hover */
}

.btn-progress {
  background: -webkit-linear-gradient(
    rgba(13, 11, 110, 0.95),
    #06053f
  ) !important;
  background: var(--main-color-darkblue-gradient);
  color: var(--main-text-light);
}

.btnn {
  border-radius: 100px !important;
  font-size: 25px;
  font-weight: 700;
  color: var(--main-text-light);
  height: 70px !important;
}

.btnn:hover {
  color: #7a80d8 !important;
}

.p-ttop {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 11px;
}

.col-te {
  font-size: 16px;
}

.to-top {
  box-shadow: var(--main-text-shadow);
  background-color: var(--main-color-darkblue-gradient);
}

.cancel {
  border-radius: 20px;
  padding: 3px;
}

.bg-primary2 {
  background: var(--main-color-blue);
  background: var(--main-color-darkblue-gradient);
}

input[type="checkbox"] {
  transform: scale(2);
  -ms-transform: scale(2);
  -webkit-transform: scale(2);
  padding: 10px;
}

.concent {
  font-size: 1rem;
}

.checkbtn {
  border-radius: none !important;
}

/* Devugo styles
--------------------------------------------------------------*/

.error-value {
  color: #dc3545;
}

.upload-pill {
  background: green;
  border-radius: 10px;
  padding: 5px;
}

.upload-pill button {
  color: white;
}

.upload-pill button:hover {
  color: white;
}

#nin-not__validated input,
#nin-not__validated select,
#nin-not__validated textarea {
  pointer-events: none !important;
}

#instruction-title {
  color: #0f0f0f !important;
}

.instruction-head {
  color: #005bb9;
  font-size: x-large;
}

.nimc-photo {
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.nimc-photo:hover {
  transform: scale(1.03);
}

@media print {
  /* All your print styles go here */
  #hide-on-print,
  .header {
    display: none !important;
  }
}

/* CSS for Credit Card Payment form */
.credit-card-box .panel-title {
  display: inline;
  font-weight: bold;
}
.credit-card-box .form-control.error {
  border-color: red;
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(255, 0, 0, 0.6);
}
.credit-card-box label.error {
  font-weight: bold;
  color: red;
  padding: 2px 8px;
  margin-top: 2px;
}
.credit-card-box .payment-errors {
  font-weight: bold;
  color: red;
  padding: 2px 8px;
  margin-top: 2px;
}
.credit-card-box label {
  display: block;
}
/* The old "center div vertically" hack */
.credit-card-box .display-table {
  display: table;
}
.credit-card-box .display-tr {
  display: table-row;
}
.credit-card-box .display-td {
  display: table-cell;
  vertical-align: middle;
  width: 50%;
}
/* Just looks nicer */
.credit-card-box .panel-heading img {
  min-width: 180px;
}
