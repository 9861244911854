.news {
  .news-image-cont {
    @media (min-width: 800px) {
      height: 390px;
    }
    @media (max-width: 800px) {
      height: 180px;
    }
    overflow: hidden;
    object-fit: center;
    object-position: center;
    img {
      object-fit: cover;
      object-position: center;
    }
  }
}
