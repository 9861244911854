@import "./../../../../Assets/scss/primaryColor";
.home {
  // background-color: rgb(255, 255, 255);
  // background-image: linear-gradient( to bottom, rgba(2, 36, 68, 0.877),
  // rgba(2, 37, 69, 0.842) ),
  //                  url(../../../../Assets/images/police.jpeg);
  // background-position: top;
  // background-repeat: no-repeat;
  // background-size: cover;
  // background-attachment: fixed;
  // height: 75vh;
  // width: 100%;
  // overflow: hidden;

  .container {
    .header {
      border-bottom: 1px solid white;
    }

    .section-content {
      height: 200px;
      width: 100%;
      border-radius: 20px;
      overflow: hidden !important;
      background-position: center;
      background-size: contain;
      cursor: pointer;
      transition: all 0.5s ease-in-out;
      background-size: cover;
      background-repeat: no-repeat;

      .section-content-inner {
        h3 {
          font-size: 2rem;
          color: rgb(228, 228, 0);
          // color: #ffffff;
          font-weight: 900;
        }
      }
      &:hover {
        transform: scale(1.05);
      }
    }

    .vertical-center {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
    }

    .right {
      border-left: 1px solid white;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-between;
    }

    h1 {
      font-weight: 700;
      color: #ffffff;
      text-transform: capitalize !important;
      font-size: 2rem;
    }

    h4 {
      font-weight: 700;
      color: #ffffff;
      text-transform: capitalize !important;
    }

    p {
      color: #ffffff;
    }

    .btn-anim {
      animation: move 3s infinite ease-in-out;
    }

    @keyframes move {
      0% {
        transform: scale(1) rotate(0deg);
      }
      100% {
        transform: scale(1.1) rotate(0.1deg);
      }
    }
    button {
      background-color: #eb0202;
      color: honeydew;
      border: 1px solid honeydew;

      &:hover {
        background-color: #002a38;
        color: honeydew;
        border: 1px solid honeydew;
      }
    }

    .btn--default {
      color: honeydew;
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0.4rem 2rem;
      border-radius: 1.5rem;
      box-shadow: rgba(224, 226, 224, 0.815) 0px 1.1px 1rem;
      transition: all 0.4s;
    }

    .countdownTime {
      font-size: 1.5rem;
      font-weight: bolder;
      color: honeydew;
    }

    .job-note-row1 {
      color: #ffffff;
      font-size: 17px;
      font-weight: bolder;
    }

    .job-note-row2 {
      color: #ffffff;
      font-size: 14px;
    }

    .job-note-row3 {
      color: #ffffff;
      font-size: 20px;
      font-weight: bold;
    }
  }

  .view-guidelines-btn {
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }
}
.contact-section {
  h4 {
    font-size: 2rem;
    font-weight: 500;
  }
  .btn {
    // background-color: yellow;
    width: 150px;
    font-weight: 700;
    font-size: 1rem;
  }
}

.bg-secondary-0 {
  background-color: #e9e9e9;
}
.news-home {
  h3 {
    font-size: 1.4rem;
  }
  .card {
    position: relative;
    .home-news-image {
      filter: brightness(40%);
    }
    .home-news-image:after {
      // content: "";
      // display: block;
      // background: rgba(0, 0, 0, 0.7);
    }
    h4 {
      font-size: 1.2rem;
      color: #000000;
      font-weight: 700;
      padding-bottom: 0px !important;
    }
    .card-news-date {
      font-size: 0.8rem;
      color: rgb(255, 166, 0);
      font-weight: 600;
    }

    .card-news-date::after {
      font-size: 0.8rem;
      width: 200px;
      height: 10px;
      content: " ";
      border-bottom: 2px solid #eb0202;
      border-width: 20px;
    }

    .card-new-content {
      font-size: 0.9rem;
      opacity: 0.7;
    }
    transition: all 0.5s ease-in-out;
    &:hover {
      transform: scale(1.05);
    }
  }
}
