@import './../../../../../Assets/scss/primaryColor';

.cbt-details {
    @media (min-width:500px)  {
        .cbt-title{
            font-size: 2.9rem; 
            font-weight: 900;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            text-transform: capitalize;
        }
    }
    
    @media (max-width:500px) {
        .cbt-title{
            font-size: 2.0rem; 
            font-weight: 900;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            text-transform: capitalize;
        }
    }
    
    .cbt-heading{
        background-color: $primary;
    }
    .cbp-logo{
        width:120px !important
    }

    .cbt-profile-photo{
     border-radius: 4px;  
     width: 250px;
     height: 230px;
    }

    .cbt-profile-photo-content{
       
        overflow: hidden; 
        width: 250px;
        height: 240px;

        position: relative; 
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;  
    }

    .cpb-note{
        font-size: 14px;
        padding-top: 10px;
    }
    .card-status-header{
        background-color:  $primary;
        .cbt-title{
            font-size: 1.5rem;
            padding: 10px 0;
        }
    }
 
    button:hover{
        background-color: #eb0202b7;
        color:honeydew;
        border:1px solid honeydew; 
    }

    a:hover{ 
        text-decoration: none;
    }
    
    .btn--default{
      background-color: #eb0202;
      color: honeydew;
      text-transform: uppercase;
      font-size: 1.2rem;
      font-weight: 600;
      padding: .4rem 2rem;
      border-radius: 1.5rem;
      box-shadow:  rgba(224, 226, 224, 0.815) 0px 1.1px 1.0rem;
      transition: all .4s;
      }

      h5{
          font-weight: bolder;
          font-size: 1.5rem;
          text-transform: capitalize
      }
      p{ 
          font-size: 1.2rem;  
      }

       
    .close-col{
    height:100px;
    .pcolor1 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        height: 4px;
        width: 100%;   
        margin: 30px 0;
   
    
    .section-color-tage1 {
        background-color: rgb(28, 128, 22)
    }
    
    .section-color-tage2 {
        background-color: rgb(30, 115, 243)
    } 
    
    .section-color-tage3 {
        background-color: rgb(251, 254, 0)
    } 
    .section-color-tage4 {
        background-color: rgb(28, 128, 22)
    } 
    .section-color-tage5 {
        background-color: rgb(30, 115, 243)
    }  

    }
  }


}
