footer {
  padding: 3px 0 0px 0;
  position: relative;
  bottom: 0;

  .reg-footer {
    background: #002a38;
    // background: #28338f;
    padding: 40px 0 40px 0;
    position: relative;
    left: 0;
    color: #ffffff;
    font-size: 14px;

    width: 100%;
    overflow: hidden;
  }

  .reg-jkk {
    font-size: 16px;
    font-style: normal;
    font-weight: bolder;
    color: #ff0101;
  }

  .reg-pwrd {
    font-size: 16px;
    font-style: normal;
    color: #ffffff;
  }

  .pcolorFooter {
    display: grid;
    grid-template-columns: 1.5fr 1fr 1.5fr;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 999;

    .section-color-tage1 {
      background-color: rgb(1, 0, 103);
    }

    .section-color-tage2 {
      background-color: rgb(251, 254, 0);
    }

    .section-color-tage3 {
      background-color: rgb(28, 128, 22);
    }
  }
}
