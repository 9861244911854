.news-detail {
  .card--img {
    @media (max-width: 500px) {
      height: 220px;
    }
    @media (min-width: 500px) {
      height: 350px;
    }
    overflow-y: hidden;
    object-position: center;
    img {
      object-position: center;
    }
  }
}
