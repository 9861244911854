.regProcess {
    .npf-heading{
        font-weight: bolder;
        font-size: 28px;
    }
    .status-style{
        background-color: rgb(128, 128, 128);
    }
    .app-Process__style{
        font-size: 26px;
        font-family: 'poppins';
    }

    .card-height {
        height: 10em !important;
    }


    .regProcess--hover{
        transition: all 0.3s ease-in-out
    }
    .regProcess--hover:hover{
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.322);
        scale: 1.01;
     ;
    }
    
    .item-id-style {
        font-weight: bolder;
        // background-color: yellow;

        text-align: end;
        text-decoration: wavy;
        font-size: 20px;
    }

    .text-item__title-style {
        text-transform:uppercase;
    }
    .text-item__description-style{
        font-size: small;
        font-weight: 400;
        color: rgb(56, 55, 55)
    }

    /* HTML: <div class="ribbon">Your text content</div> */
    .ribbon {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
    }

    .ribbon {
        --f: .5em;
        /* control the folded part*/
        --r: .8em;
        /* control the ribbon shape */

        position: absolute;
        text-align: center;
        right: 20px;
        top: calc(-1*var(--f));
        padding: .2em;
        background: #010067;
        border-left: var(--f) solid #0005;
        border-bottom: var(--r) solid #0000;
        clip-path: polygon(var(--f) 0, 100% 0, 100% 100%, calc(50% + var(--f)/2) calc(100% - var(--r)), var(--f) 100%, var(--f) var(--f), 0 var(--f));
    }
}