.card{
    p{
        color:rgb(231, 229, 229);
        font-weight: bold;
        font-size: 1rem;
    }
    h4{
        color:rgb(231, 229, 229);
        font-weight: bold;
        font-size: 2rem; 
    }
    .desc{
        color:rgb(255, 254, 254) !important; 
        font-size: .8rem; 
    }
    transition: transform .5s;
 }