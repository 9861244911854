.confirm-ui {
  background-color: rgb(253, 253, 253);

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);

  border-radius: 24px !important;

  @media (max-width: 500px) {
    width: 100% !important;
    height: 379px !important;
  }
  @media (min-width: 500px) {
    width: 401px !important;
    height: 439px !important;
  }

  .card {
    height: 100%;

    .icon-container {
      width: 82px;
      height: 82px;
      display: flex;
      justify-self: center;
      align-self: center;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      .d-icon {
        font-size: 3rem;
        font-weight: 900;
        color: white;
      }
    }
  }
}
.overlay-custom-class-name {
  background-color: #8080803b !important;
}
