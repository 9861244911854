@import "../../Assets/scss/primaryColor";
.loader {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.705);
  top: 0;
  bottom: 0;
  background-attachment: fixed;
  .spinner-border {
    width: 13rem;
    height: 13rem;
    color: rgb(10, 10, 10);
  }

  .bold-loader-text {
    font-size: 1.2rem;
    font-weight: 900;
    text-shadow: none;
  }
}

.loader____bg {
  .spinner-border {
    width: 10rem !important;
    height: 10rem !important;
    color: rgb(10, 10, 10);
  }
}
