/*********************************
            Contact Page
*********************************/
.contact {
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
      to bottom,
      rgba(252, 252, 252, 0.959),
      rgba(248, 248, 248, 0.966)
    ),
    url(https://hometown.ng/wp-content/uploads/2016/11/tumblr_inline_nttutyJLHp1sjy75e_540.jpg);
  //  background-image: linear-gradient( to bottom, rgba(252, 252, 252, 0.897),
  //  rgba(248, 248, 248, 0.959) ),
  //  url(https://hometown.ng/wp-content/uploads/2016/11/tumblr_inline_nttutyJLHp1sjy75e_540.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .contact-info__card {
    background: var(--color-white);
    border-radius: 5px;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    // -webkit-box-shadow: 0 2px 2.8rem 0 rgba(0, 0, 0, 0.08);
    // box-shadow: 0 2px 1.8rem 0 rgba(0, 0, 0, 0.08);
    padding: 3rem;
  }

  .contact-info__card:hover .icon-wrap--grey {
    background: var(--color-secondary);
    color: var(--color-white);
  }

  .contact-info__card .icon-wrap--grey {
    background: var(--color-grey-light-5);
    color: var(--color-secondary);
    font-size: 1.3rem;
    margin-bottom: 1.2rem;
    -webkit-transition: 0.5s;
    transition: 0.5s;
  }

  .contact-info__heading {
    margin-bottom: 1.2rem;
    font-size: 1.3rem;
    font-weight: 700;
  }

  .contact-info__card p a {
    color: #6b6b84;
  }

  .contact-info__card p a:hover {
    color: var(--color-secondary);
    text-decoration: none;
  }

  .contact-form input,
  .contact-form textarea {
    font-size: inherit;
    padding: 2rem 1.5rem;
  }

  .contact-form .form-group {
    margin-bottom: 2rem;
  }

  .contact-form .btn {
    width: 100%;
  }

  .contact-map__map {
    width: 100%;
    height: 27rem;
  }

  .contact-details__icon-wrap i {
    color: var(--color-secondary);
  }

  .contact-info__card .icon-wrap--grey {
    background: rgb(250, 249, 249);
    color: #f50000;
    font-size: 1.3rem;
    margin-bottom: 1.2rem;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    border-radius: 50px;
    width: 100px;
    height: 100px;
  }

  button {
    background-color: #f50000;
    color: honeydew;
    border: 1px solid honeydew;
  }
  button:hover {
    background-color: #f50000e5;
    color: honeydew;
    border: 1px solid honeydew;
  }
  .btn--default {
    color: honeydew;
    text-transform: uppercase;
    font-size: 1.2rem;
    font-weight: 600;
    padding: 0.4rem 2rem;
    border-radius: 1.5rem;
    box-shadow: rgba(224, 226, 224, 0.815) 0px 3px 1.5rem;
    transition: all 0.4s;
  }
}
