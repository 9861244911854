.slip-confirmation {
  background-color: rgb(255, 255, 255);
  background-image: linear-gradient(
      to bottom,
      rgba(2, 36, 68, 0.877),
      rgba(2, 37, 69, 0.842)
    ),
    url(../../../../../Assets/images/police.jpeg);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
  background-attachment: fixed;
  height: 100vh;
  width: 100vw;

  .register-inner {
    height: 70vh;
  }
}
