@import './../../../../../Assets/scss/primaryColor';

.cbt-details {
    @media (min-width:500px)  {
        .cbt-title{
            font-size: 2.2rem; 
            font-weight: 800;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            text-transform: capitalize;
        }
    }
    
    @media (max-width:500px) {
        .cbt-title{
            font-size: 1.7rem; 
            font-weight: 900;
            font-family: Verdana, Geneva, Tahoma, sans-serif;
            text-transform: capitalize;
        }
    }
    
    .cbt-heading{
        background-color: $primary;
    }
    .cbp-logo{
        width:120px !important
    }

    .cbt-profile-photo{
     border-radius: 4px;  
     width: 250px;
     height: 230px;
    }

    .cbt-profile-photo-content{
       
        overflow: hidden; 
        width: 250px;
        height: 240px;

        position: relative; 
        left: 0; 
        right: 0; 
        margin-left: auto; 
        margin-right: auto;  
    }

    .cpb-note{
        font-size: 14px;
        padding-top: 10px;
    }
}
